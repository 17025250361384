import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/display.css'
import './styles/element/index.scss'
import CKEditor from '@ckeditor/ckeditor5-vue'

import Parse from 'parse/dist/parse.min.js'
console.log(process.env.NODE_ENV)
/** 正式環境 */
if (process.env.NODE_ENV === 'development') {
  Parse.initialize('bwpublish-dev', 'ecd34ec9-1843-42cb-b8b4-2418a3a9e617')
  Parse.serverURL = 'https://parseapi-dev.bwpublish.com/parse'
} else if (process.env.NODE_ENV === 'production') {
  Parse.initialize('bwpublish', 'ba80bee6-90be-4795-9b7c-36ac881b9c66')
  Parse.serverURL = 'https://parseapi.bwpublish.com/parse'
}

// // /** 測試環境 */
// Parse.initialize('bwpublish-dev', 'ecd34ec9-1843-42cb-b8b4-2418a3a9e617')
// Parse.serverURL = 'https://parseapi-dev.bwpublish.com/parse'

// // Parse.initialize('bwpublish', 'ba80bee6-90be-4795-9b7c-36ac881b9c66')
// // Parse.serverURL = 'https://parseapi.bwpublish.com/parse'

import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

const app = createApp(App).use(Quasar, quasarUserOptions)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Vue3Lottie)
app.use(CKEditor)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.mount('#app')
