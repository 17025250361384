<template>
  <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';

.sidebar-nav .nav-group .nav-link.nav-group-toggle {
  color: white;
}

.sidebar-nav .nav-item .nav-link {
  color: white;
}

.sidebar-nav .nav-item .nav-link svg {
  color: white;
}

// 選單顏色修改
.sidebar.sidebar-fixed > .sidebar-nav > .nav-group:nth-of-type(2) {
  background-color: #7a9556;
}

.sidebar.sidebar-fixed > .sidebar-nav > .nav-group:nth-of-type(3) {
  background-color: #d77636;
}

.sidebar.sidebar-fixed > .sidebar-nav > .nav-group:nth-of-type(4) {
  background-color: #ae1c2d;
}
</style>
