// 心閱網
export const homepage = [
  {
    component: 'CNavTitle',
    name: '首頁',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavGroup',
    name: '首頁大圖管理',
    icon: 'cil_images',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
    items: [
      {
        component: 'CNavItem',
        name: '上方大圖',
        to: '/homepage/carouselMain',
      },
      {
        component: 'CNavItem',
        name: '下方小圖',
        to: '/homepage/carouselSub',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: '最新文章管理',
    icon: 'cil_list',
    to: '/homepage/latestArticleManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '首頁特別企劃',
    icon: 'cil_flag',
    to: '/homepage/specialProject',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '關鍵字管理',
    icon: 'cil_aim',
    to: '/homepage/trendingHashTagManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

export const articleNactivity = [
  {
    component: 'CNavTitle',
    name: '文章/活動專區',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '文章 / 活動',
    icon: 'cil-notes',
    to: '/articleNactivityBlock/articleNactivity',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '文章特別企劃',
    icon: 'cil_flag',
    to: '/articleNactivityBlock/articleSpecialProject',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '標籤管理',
    icon: 'cil_tag',
    to: '/articleNactivityBlock/tagManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  // {
  //   component: 'CNavItem',
  //   name: '文章匯入',
  //   icon: 'cil_tag',
  //   to: '/articleNactivityBlock/articleImport',
  // },
]

export const drawLots = [
  {
    component: 'CNavTitle',
    name: '每日一籤',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '法語管理',
    icon: 'cil-burn',
    to: '/drawLots/HeartOfTheHeroManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavGroup',
    name: '金句管理',
    icon: 'cil_hope',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
    items: [
      {
        component: 'CNavItem',
        name: '金句維護',
        to: '/drawLots/GoodSentenceManage',
      },
      {
        component: 'CNavItem',
        name: '類別維護',
        to: '/drawLots/GoodSentenceCategory',
      },
    ],
  },
  // {
  //   component: 'CNavItem',
  //   name: '法語匯入',
  //   icon: 'cil-burn',
  //   to: '/drawLots/drawLotsImport',
  // },
]

export const videos = [
  {
    component: 'CNavTitle',
    name: '影音專區',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '影音管理',
    icon: 'cil_video',
    to: '/videosBlock/videoManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '影音特別企劃',
    icon: 'cil_flag',
    to: '/videosBlock/videoSpecialProject',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

export const systemSettings = [
  {
    component: 'CNavTitle',
    name: '系統設定',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: 'Logo維護',
    icon: 'cil_logo',
    to: '/systemSetting/logoManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

// 福田書屋
export const storeHomePage = [
  {
    component: 'CNavTitle',
    name: '首頁',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavGroup',
    name: '首頁大圖管理',
    icon: 'cil_images',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
    items: [
      {
        component: 'CNavItem',
        name: '上方大圖',
        to: '/store/homepage/carouselMain',
      },
      {
        component: 'CNavItem',
        name: '下方小圖',
        to: '/store/homepage/carouselSub',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: '最新公告管理',
    icon: 'cil_list',
    to: '/store/homepage/latestAnnounceManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '編輯最前線',
    icon: 'cil_chat',
    to: '/store/homepage/latestArticleManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '精選商品管理',
    icon: 'cil_goldMedal',
    to: '/store/homepage/selectedGoodsManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

export const storeOnlineBookStore = [
  {
    component: 'CNavTitle',
    name: '線上書店',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '全部商品管理',
    icon: 'cil_goods',
    to: '/store/onlineBookStore/allGoods',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '書店分頁主圖',
    icon: 'cil_logo',
    to: '/store/onlineBookStore/carousels',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '近期主打商品',
    icon: 'cil_magicStick',
    to: '/store/onlineBookStore/recentlyMainGoods',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '書店標籤管理',
    icon: 'cil_tag',
    to: '/store/onlineBookStore/bookTagManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

export const storeOrderManage = [
  {
    component: 'CNavTitle',
    name: '訂單管理',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '訂單管理',
    icon: 'cil_documentCopy',
    to: '/store/order/orderManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

export const storeEditorManage = [
  {
    component: 'CNavTitle',
    name: '線上編輯室/活動管理',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '文章/活動管理',
    icon: 'cil_document',
    to: '/store/editor/articleNactivity',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '編輯室分頁主圖',
    icon: 'cil_logo',
    to: '/store/editor/gridImageGalleryManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '編輯室標籤管理',
    icon: 'cil_tag',
    to: '/store/editor/articleTagsManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

export const storeThangkaBlock = [
  {
    component: 'CNavTitle',
    name: '唐卡專區',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '唐卡分頁主圖',
    icon: 'cil_scaleToOriginal',
    to: '/store/thangkaBlock/gridImageGalleryManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '精選類別管理',
    icon: 'cil_folderOpened',
    to: '/store/thangkaBlock/selectedCategoryManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '近期精選管理',
    icon: 'cil_dataLine',
    to: '/store/thangkaBlock/selectedRecentlyManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

export const storeSystemSettings = [
  {
    component: 'CNavTitle',
    name: '系統設定',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: 'Logo管理',
    icon: 'cil_logo',
    to: '/store/systemSetting/logoManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

// 聞法教室
export const classicHomepage = [
  {
    component: 'CNavTitle',
    name: '首頁',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '大圖管理',
    icon: 'cil_images',
    to: '/classic/homepage/carousel',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '最新文章管理',
    icon: 'cil_list',
    to: '/classic/homepage/latestArticleManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '精選課程管理',
    icon: 'cil_magicStick',
    to: '/classic/homepage/featuredCoursesManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '關鍵字管理',
    icon: 'cil_aim',
    to: '/classic/homepage/trendingHashTagManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

export const classicArticleNNews = [
  {
    component: 'CNavTitle',
    name: '文章/最新消息',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '大圖管理',
    icon: 'cil_images',
    to: '/classic/articleNNews/carousel',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '文章 / 最新消息',
    icon: 'cil-notes',
    to: '/classic/articleNNews/articleNNews',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: '標籤管理',
    icon: 'cil_tag',
    to: '/classic/articleNNews/tagManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  // {
  //   component: 'CNavItem',
  //   name: '文章匯入',
  //   icon: 'cil_tag',
  //   to: '/articleNactivityBlock/articleImport',
  // },
]

export const classicVideos = [
  {
    component: 'CNavTitle',
    name: '影音專區',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '影音管理',
    icon: 'cil_video',
    to: '/classic/videosBlock/videoManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

export const classicSystemSettings = [
  {
    component: 'CNavTitle',
    name: '系統設定',
    class: 'sidebar-title-indent sidebar-title',
  },
  {
    component: 'CNavItem',
    name: '最新廣告',
    icon: 'cil_trophyBase',
    to: '/classic/systemSetting/announcementManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
  {
    component: 'CNavItem',
    name: 'Logo維護',
    icon: 'cil_logo',
    to: '/classic/systemSetting/logoManage',
    iconClass: 'sidebar-icon-indent',
    class: 'sidebar-item-indent',
  },
]

// sidebar最外層
export const navContents = {
  welcome: [
    {
      component: 'CNavItem',
      name: 'Welcome',
      icon: 'cil_home',
      to: '/welcome',
    },
  ],
  bwpublish: [
    {
      component: 'CNavGroup',
      name: '心閱網',
      icon: 'cil_reading',
      items: [],
    },
  ],
  store_bwpublish: [
    {
      component: 'CNavGroup',
      name: '福田書屋',
      icon: 'cil_cart',
      items: [],
    },
  ],
  classic: [
    {
      component: 'CNavGroup',
      name: '聞法教室',
      icon: 'cil_dataLine',
      items: [],
    },
  ],

  authority: [
    {
      component: 'CNavTitle',
      name: '權限設定',
      class: 'sidebar-title',
    },
    {
      component: 'CNavItem',
      name: '帳號管理',
      icon: 'cil_person',
      to: '/authority/accountManage',
    },
    // {
    //   component: 'CNavItem',
    //   name: '會員匯入',
    //   icon: 'cil-burn',
    //   to: '/authority/userImport',
    // },
  ],
}
