<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar
        :src="avatar"
        size="lg"
        class="avatar"
        color="dark"
        text-color="dark"
      />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        歡迎，{{ userName }}
      </CDropdownHeader>
      <!-- <CDropdownItem>
        <CIcon icon="cil-bell" /> Updates
        <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> Messages
        <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-task" /> Tasks
        <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-comment-square" /> Comments
        <CBadge color="warning" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader>
      <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-dollar" /> Payments
        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-file" /> Projects
        <CBadge color="primary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem>
        <CIcon icon="cil-shield-alt" /> Lock Account
      </CDropdownItem> -->
      <CDropdownItem @click="logOut()">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script setup>
// import avatar from '@/assets/images/avatars/8.jpg'
import Parse from 'parse/dist/parse.min.js'
import { ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store'

const avatar = ref('')
const userName = ref()
const router = useRouter()
// const itemsCount = 5
const currentUser = store.state.user
const logOut = () => {
  Parse.User.logOut().then(() => {
    router.push({ name: 'Login' })
  })
}

onBeforeMount(async () => {
  if (currentUser) {
    avatar.value = currentUser.get('avatar')._url
    userName.value = currentUser.get('nickname')
  }
})
</script>
<!-- <script>
import avatar from '@/assets/images/avatars/8.jpg'
import { ref } from 'vue'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
}
</script> -->
<style lang="scss" scoped>
.avatar {
  cursor: pointer !important;
  :deep(img) {
    cursor: pointer !important;
    object-fit: cover !important;
    height: 48px;
    width: 48px;
  }
}

.nav-item {
  :deep(a) {
    opacity: 1 !important;
    cursor: default !important;
  }
}
</style>
