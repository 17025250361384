// import {
//   cibFacebook,
//   cibTwitter,
//   cibLinkedin,
//   cibFlickr,
//   cibTumblr,
//   cibXing,
//   cibGithub,
//   cibGoogle,
//   cibStackoverflow,
//   cibYoutube,
//   cibDribbble,
//   cibInstagram,
//   cibPinterest,
//   cibVk,
//   cibYahoo,
//   cibBehance,
//   cibReddit,
//   cibVimeo,
//   cibCcMastercard,
//   cibCcVisa,
//   cibCcStripe,
//   cibCcPaypal,
//   cibCcApplePay,
//   cibCcAmex,
// } from '@coreui/icons'
// import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from '@coreui/icons'
import {
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilBurn,
  cilImage,
} from '@coreui/icons'
import {
  cil_images,
  cil_list,
  cil_flag,
  cil_aim,
  cil_tag,
  cil_hope,
  cil_video,
  cil_logo,
  cil_person,
  cil_home,
  cil_reading,
  cil_cart,
  cil_goods,
  cil_magicStick,
  cil_documentCopy,
  cil_document,
  cil_scaleToOriginal,
  cil_folderOpened,
  cil_dataLine,
  cil_chat,
  cil_goldMedal,
  cil_trophyBase,
} from './self-icon'

export const iconsSet = Object.assign(
  {},
  {
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilBurn,
    cilImage,
  },
  {
    // cifUs,
    // cifBr,
    // cifIn,
    // cifFr,
    // cifEs,
    // cifPl,
  },
  {
    // cibFacebook,
    // cibTwitter,
    // cibLinkedin,
    // cibFlickr,
    // cibTumblr,
    // cibXing,
    // cibGithub,
    // cibGoogle,
    // cibStackoverflow,
    // cibYoutube,
    // cibDribbble,
    // cibInstagram,
    // cibPinterest,
    // cibVk,
    // cibYahoo,
    // cibBehance,
    // cibReddit,
    // cibVimeo,
    // cibCcMastercard,
    // cibCcVisa,
    // cibCcStripe,
    // cibCcPaypal,
    // cibCcApplePay,
    // cibCcAmex,
  },
  {
    cil_images,
    cil_list,
    cil_flag,
    cil_aim,
    cil_tag,
    cil_hope,
    cil_video,
    cil_logo,
    cil_person,
    cil_home,
    cil_reading,
    cil_cart,
    cil_goods,
    cil_magicStick,
    cil_documentCopy,
    cil_document,
    cil_scaleToOriginal,
    cil_folderOpened,
    cil_dataLine,
    cil_chat,
    cil_goldMedal,
    cil_trophyBase,
  },
)
