import { defineComponent, h, ref, resolveComponent } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import store from '@/store'
// import Parse from 'parse/dist/parse.min.js'

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from '@coreui/vue'

import {
  navContents,
  homepage,
  articleNactivity,
  drawLots,
  videos,
  systemSettings,
  storeHomePage,
  storeOnlineBookStore,
  storeOrderManage,
  storeEditorManage,
  storeThangkaBlock,
  storeSystemSettings,
  classicHomepage,
  classicArticleNNews,
  classicVideos,
  classicSystemSettings,
} from '@/_nav'

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }
  // if (route.path.startsWith(link)) {
  //   return true
  // }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)
  return currentPath.startsWith(targetPath)
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute()
    const firstRender = ref(true)

    const nav = ref([])
    // 權限判定
    const user = store.state.user

    const authorities = ref([])
    const storeAuthority = ref([])
    const classicAuthority = ref([])
    const userAccountAuthority = ref([])

    if (user) {
      authorities.value = user.get('authority')
      storeAuthority.value = user.get('store_authority')
      classicAuthority.value = user.get('classic_authority')
      userAccountAuthority.value = user.get('userAccount_authority')

      const authorityContentMap = {
        homepage: homepage,
        articleNactivity: articleNactivity,
        drawLots: drawLots,
        videos: videos,
        settings: systemSettings,
      }

      const storeAuthorityContentMap = {
        homepage: storeHomePage,
        onlineBookStore: storeOnlineBookStore,
        order: storeOrderManage,
        editor: storeEditorManage,
        thangkaBlock: storeThangkaBlock,
        systemSetting: storeSystemSettings,
      }

      const classicAuthorityContentMap = {
        homepage: classicHomepage,
        articleNNews: classicArticleNNews,
        videos: classicVideos,
        settings: classicSystemSettings,
      }
      // 心閱網
      navContents.bwpublish[0].items = []
      if (authorities.value && authorities.value.length > 0) {
        for (const [authority, content] of Object.entries(
          authorityContentMap,
        )) {
          if (authorities.value.includes(authority)) {
            navContents.bwpublish[0].items.push(...content)
          }
        }
        nav.value.push(...navContents.bwpublish)
      }
      // 福田書屋
      navContents.store_bwpublish[0].items = []
      if (storeAuthority.value && storeAuthority.value.length > 0) {
        for (const [authority, content] of Object.entries(
          storeAuthorityContentMap,
        )) {
          if (storeAuthority.value.includes(authority)) {
            navContents.store_bwpublish[0].items.push(...content)
          }
        }
        nav.value.push(...navContents.store_bwpublish)
      }
      // 聞法教室
      navContents.classic[0].items = []
      if (classicAuthority.value && classicAuthority.value.length > 0) {
        for (const [authority, content] of Object.entries(
          classicAuthorityContentMap,
        )) {
          if (classicAuthority.value.includes(authority)) {
            navContents.classic[0].items.push(...content)
          }
        }
        nav.value.push(...navContents.classic)
      }
      // 帳號管理
      if (userAccountAuthority.value && userAccountAuthority.value[0]) {
        nav.value.push(...navContents.authority)
      }
      // welcome
      nav.value.unshift(...navContents.welcome)
    }

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
            class: item.class ? item.class : '',
          },
          {
            togglerContent: () => [
              h(resolveComponent('CIcon'), {
                customClassName: `nav-icon ${
                  item.iconClass ? item.iconClass : ''
                }`,
                name: item.icon,
              }),
              item.name,
            ],
            default: () => item.items.map((child) => renderItem(child)),
          },
        )
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: isActiveLink(route, item.to),
                    href: props.href,
                    // onClick: () => props.navigate(),
                    class: item.class
                      ? isActiveLink(route, item.to)
                        ? `${item.class} bwSidebarItemActive`
                        : item.class
                      : '',
                  },
                  {
                    default: () => [
                      item.icon &&
                        h(resolveComponent('CIcon'), {
                          customClassName: `nav-icon ${
                            item.iconClass ? item.iconClass : ''
                          }`,
                          name: item.icon,
                        }),
                      item.name,
                      item.badge &&
                        h(
                          CBadge,
                          {
                            class: 'ms-auto',
                            color: item.badge.color,
                          },
                          {
                            default: () => item.badge.text,
                          },
                        ),
                    ],
                  },
                ),
            },
          )
        : h(
            resolveComponent(item.component),
            {
              class: item.class ? item.class : '',
            },
            {
              default: () => item.name,
            },
          )
    }

    // onMounted(() => {
    //   setTimeout(() => {
    //     firstRender.value = false
    //   }, 1000)
    // })
    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () => nav.value.map((item) => renderItem(item)),
        },
      )
  },
})
export { AppSidebarNav }
