import { createStore } from 'vuex'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    // 使用者保存
    user: '',
    // 心閱網文章預覽暫存
    articleNactivity: '',
    // 心閱網文章列表類別
    editingParentCategory: 'article',
    categorys: [],
    // 福田書屋
    storeDomain: 'https://store.bwpublish.com/',
    // 福田書屋文章預覽暫存
    storeArticleNactivity: '',
    // 福田書屋文章列表類別
    storeEditingParentCategory: 'article',
    // 聞法教室文章預覽暫存
    classicArticleNNews: '',
    // 聞法教室文章列表類別
    classicEditingParentCategory: 'article',
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    updateArticleNactivity(state, payload) {
      state.articleNactivity = payload
    },
    updateUser(state, payload) {
      state.user = payload
    },
    editingParentCategory(state, payload) {
      state.editingParentCategory = payload
    },
    setCategorys(state, payload) {
      state.categorys = payload
    },
    updateStoreArticleNactivity(state, payload) {
      state.storeArticleNactivity = payload
    },
    updateStoreEditingParentCategory(state, payload) {
      state.storeEditingParentCategory = payload
    },
    updateClassicArticleNNews(state, payload) {
      state.classicArticleNNews = payload
    },
    updateClassicArticleParentCategory(state, payload) {
      state.classicEditingParentCategory = payload
    },
  },
  actions: {},
  modules: {},
})
