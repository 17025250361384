import { h, resolveComponent } from 'vue'
// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store'
import Parse from 'parse/dist/parse.min.js'
// 因為name具有唯一性，而breadcrumb會用到name，所以在名稱前加上三個字，在breadcrumb.vue會切除
const routes = [
  {
    path: '/',
    name: '共有的心閱網後台',
    component: DefaultLayout,
    redirect: '/welcome',
    children: [
      {
        path: '/welcome',
        name: '共有的Welcome',
        component: () => import('@/views/Welcome.vue'),
      },
      {
        path: '/homepage',
        name: '心閱網首頁',
        redirect: '/homepage/carouselMain',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: '心閱網上方大圖',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/homepage/carouselMain',
            children: [
              {
                path: 'carouselMain',
                name: '心閱網上方大圖',
                component: () =>
                  import(
                    '@/views/bwpublish/homepage/carousel/carouselMain.vue'
                  ),
              },
              {
                path: 'carouselMain/carouselAdd',
                name: '心閱網新增上方大圖',
                component: () =>
                  import(
                    '@/views/bwpublish/homepage/carousel/carouselMainAdd.vue'
                  ),
              },
              {
                path: 'carouselMain/carouselEdit/:carouselMainId',
                name: '心閱網編輯上方大圖',
                component: () =>
                  import(
                    '@/views/bwpublish/homepage/carousel/carouselMainEdit.vue'
                  ),
              },
            ],
          },
          {
            path: '',
            name: '心閱網下方小圖',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            // redirect: '/homepage/carouselSub',
            children: [
              {
                path: 'carouselSub',
                name: '心閱網下方小圖',
                component: () =>
                  import('@/views/bwpublish/homepage/carousel/carouselSub.vue'),
              },
              {
                path: 'carouselSub/carouselAdd',
                name: '心閱網新增下方小圖',
                component: () =>
                  import(
                    '@/views/bwpublish/homepage/carousel/carouselSubAdd.vue'
                  ),
              },
              {
                path: 'carouselSub/carouselEdit/:carouselSubId',
                name: '心閱網編輯下方小圖',
                component: () =>
                  import(
                    '@/views/bwpublish/homepage/carousel/carouselSubEdit.vue'
                  ),
              },
            ],
          },
          {
            path: 'latestArticleManage',
            name: '心閱網最新文章管理',
            component: () =>
              import('@/views/bwpublish/homepage/latestArticleManage.vue'),
          },
          {
            path: 'specialProject',
            name: '心閱網首頁特別企劃',
            component: () =>
              import('@/views/bwpublish/homepage/specialProject.vue'),
          },
          {
            path: 'trendingHashTagManage',
            name: '心閱網關鍵字管理',
            component: () =>
              import('@/views/bwpublish/homepage/trendingHashTM.vue'),
          },
        ],
      },
      {
        path: '/articleNactivityBlock',
        name: '心閱網文章/活動專區',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/articleNactivityBlock/articleNactivity',
        children: [
          {
            path: '',
            redirect: '/articleNactivityBlock/articleNactivity',
            name: '心閱網文章/活動',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            // redirect: '/articleNactivityBlock/articleNactivity',
            children: [
              {
                path: 'articleNactivity',
                name: '心閱網文章/活動列表',
                component: () =>
                  import(
                    '@/views/bwpublish/articleNactivityBlock/articleNactivity/articleNactivity.vue'
                  ),
              },
              {
                path: 'articleNactivityAdd',
                name: '心閱網文章/活動新增',
                component: () =>
                  import(
                    '@/views/bwpublish/articleNactivityBlock/articleNactivity/articleNactivityAdd.vue'
                  ),
              },
              {
                path: 'articleNactivityEdit/:articleNactivityId',
                name: '心閱網文章/活動編輯',
                component: () =>
                  import(
                    '@/views/bwpublish/articleNactivityBlock/articleNactivity/articleNactivityEdit.vue'
                  ),
              },
              {
                path: 'articleNactivityRead/:articleNactivityId',
                name: '心閱網文章/活動檢視',
                component: () =>
                  import(
                    '@/views/bwpublish/articleNactivityBlock/articleNactivity/articleNactivityRead.vue'
                  ),
              },
              {
                path: 'articleNactivityPreview',
                name: '心閱網文章/活動預覽',
                component: () =>
                  import(
                    '@/views/bwpublish/articleNactivityBlock/articleNactivity/articleNactivityPreview.vue'
                  ),
                beforeEnter: () => {
                  if (!store.state.articleNactivity) {
                    return {
                      path: history.back().then(() => {
                        window.location.reload()
                      }),
                    }
                  }
                },
              },
            ],
          },
          {
            path: '/articleNactivityBlock/articleSpecialProject',
            name: '心閱網文章特別企劃',
            component: () =>
              import(
                '@/views/bwpublish/articleNactivityBlock/articleSpecialProject.vue'
              ),
          },
          {
            path: 'tagManage',
            name: '心閱網標籤管理',
            component: () =>
              import('@/views/bwpublish/articleNactivityBlock/tagManage.vue'),
          },
          {
            path: 'articleImport',
            name: '心閱網文章匯入',
            component: () =>
              import(
                '@/views/bwpublish/articleNactivityBlock/articleImport.vue'
              ),
          },
        ],
      },
      {
        path: '/drawLots',
        name: '心閱網每日一籤',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/drawLots/HeartOfTheHeroManage',
        children: [
          {
            path: 'HeartOfTheHeroManage',
            name: '心閱網法語管理',
            component: () =>
              import(
                '@/views/bwpublish/drawLots/heartOfTheHero/HeartOfTheHeroManage.vue'
              ),
          },
          {
            path: 'GoodSentenceManage',
            name: '心閱網金句維護',
            component: () =>
              import(
                '@/views/bwpublish/drawLots/goodSentence/GoodSentenceManage.vue'
              ),
          },
          {
            path: 'GoodSentenceCategory',
            name: '心閱網類別維護',
            component: () =>
              import(
                '@/views/bwpublish/drawLots/goodSentence/GoodSentenceCategory.vue'
              ),
          },
          {
            path: 'drawLotsImport',
            name: '心閱網法語匯入',
            component: () =>
              import('@/views/bwpublish/drawLots/drawLotsImport.vue'),
          },
        ],
      },
      {
        path: '/videosBlock',
        name: '心閱網影音專區',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/videosBlock/videoManage',
        children: [
          {
            path: 'videoManage',
            name: '心閱網影音管理',
            component: () =>
              import('@/views/bwpublish/videosBlock/videoManage.vue'),
          },
          {
            path: 'videoSpecialProject',
            name: '心閱網影音特別企劃',
            component: () =>
              import('@/views/bwpublish/videosBlock/videoSpecialProject.vue'),
          },
        ],
      },
      {
        path: '/systemSetting',
        name: '心閱網系統設定',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/systemSetting/logoManage',
        children: [
          {
            path: 'logoManage',
            name: '心閱網Logo維護',
            component: () =>
              import('@/views/bwpublish/systemSetting/logoManage.vue'),
          },
        ],
      },
      {
        path: '/store/homepage',
        name: '小商店首頁',
        redirect: '/store/homepage/carouselMain',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: '小商店上方大圖',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/store/homepage/carouselMain',
            children: [
              {
                path: 'carouselMain',
                name: '小商店上方大圖',
                component: () =>
                  import('@/views/store/homepage/carousel/carouselMain.vue'),
              },
              {
                path: 'carouselMain/carouselAdd',
                name: '小商店新增上方大圖',
                component: () =>
                  import('@/views/store/homepage/carousel/carouselMainAdd.vue'),
              },
              {
                path: 'carouselMain/carouselEdit/:carouselMainId',
                name: '小商店編輯上方大圖',
                component: () =>
                  import(
                    '@/views/store/homepage/carousel/carouselMainEdit.vue'
                  ),
              },
            ],
          },
          {
            path: '',
            name: '小商店下方小圖',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            // redirect: '/homepage/carouselSub',
            children: [
              {
                path: 'carouselSub',
                name: '小商店下方小圖',
                component: () =>
                  import('@/views/store/homepage/carousel/carouselSub.vue'),
              },
              {
                path: 'carouselSub/carouselAdd',
                name: '小商店新增下方小圖',
                component: () =>
                  import('@/views/store/homepage/carousel/carouselSubAdd.vue'),
              },
              {
                path: 'carouselSub/carouselEdit/:carouselSubId',
                name: '小商店編輯下方小圖',
                component: () =>
                  import('@/views/store/homepage/carousel/carouselSubEdit.vue'),
              },
            ],
          },
          {
            path: 'latestAnnounceManage',
            name: '小商店最新公告管理',
            component: () =>
              import('@/views/store/homepage/latestAnnounceManage.vue'),
          },
          {
            path: 'latestArticleManage',
            name: '小商店編輯最前線',
            component: () =>
              import('@/views/store/homepage/latestArticleManage.vue'),
          },
          {
            path: 'selectedGoodsManage',
            name: '小商店精選商品管理',
            component: () =>
              import('@/views/store/homepage/selectedGoodsManage.vue'),
          },
        ],
      },
      {
        path: '/store/onlineBookStore',
        name: '小商店線上書店',
        redirect: '/store/onlineBookStore/allGoods',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: '小商店全部商品管理',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/store/onlineBookStore/allGoods',
            children: [
              {
                path: 'allGoods',
                name: '全部商品管理列表',
                component: () =>
                  import(
                    '@/views/store/onlineBookStore/allGoodsManage/allGoods.vue'
                  ),
              },
              {
                path: 'goodsAdd',
                name: '全部商品管理新增',
                component: () =>
                  import(
                    '@/views/store/onlineBookStore/allGoodsManage/goodsAdd.vue'
                  ),
              },
              {
                path: 'goodsEdit/:goodsId',
                name: '全部商品管理編輯',
                component: () =>
                  import(
                    '@/views/store/onlineBookStore/allGoodsManage/goodsEdit.vue'
                  ),
              },
              {
                path: 'goodsRead/:goodsId',
                name: '全部商品管理檢視',
                component: () =>
                  import(
                    '@/views/store/onlineBookStore/allGoodsManage/goodsRead.vue'
                  ),
              },
            ],
          },
          {
            path: '',
            name: '小商店書店分頁主圖',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/store/onlineBookStore/carousels',
            children: [
              {
                path: 'carousels',
                name: '書店分頁主圖列表',
                component: () =>
                  import(
                    '@/views/store/onlineBookStore/carousels/carousels.vue'
                  ),
              },
              {
                path: 'carouselsAdd',
                name: '書店分頁主圖新增',
                component: () =>
                  import(
                    '@/views/store/onlineBookStore/carousels/carouselsAdd.vue'
                  ),
              },
              {
                path: 'carouselsEdit/:id',
                name: '書店分頁主圖編輯',
                component: () =>
                  import(
                    '@/views/store/onlineBookStore/carousels/carouselsEdit.vue'
                  ),
              },
            ],
          },
          {
            path: 'recentlyMainGoods',
            name: '小商店書店近期主打商品',
            component: () =>
              import('@/views/store/onlineBookStore/recentlyMainGoods.vue'),
          },
          {
            path: 'bookTagManage',
            name: '小商店書店標籤管理',
            component: () =>
              import('@/views/store/onlineBookStore/bookTagsManage.vue'),
          },
        ],
      },
      {
        path: '/store/order',
        name: '小商店訂單管理',
        redirect: '/store/order/orderManage',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'orderManage',
            name: '小商店訂單管理',
            component: () => import('@/views/store/order/orderManage.vue'),
          },
        ],
      },
      {
        path: '/store/editor',
        name: '小商店線上編輯室/活動管理',
        redirect: '/store/editor/articleNactivity',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: '小商店文章/活動管理',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/store/editor/articleNactivity',
            children: [
              {
                path: 'articleNactivity',
                name: '編輯室列表',
                component: () =>
                  import(
                    '@/views/store/editor/articleNactivity/articleNactivity.vue'
                  ),
              },
              {
                path: 'articleNactivityRead/:articleNactivityId',
                name: '編輯室檢視',
                component: () =>
                  import(
                    '@/views/store/editor/articleNactivity/articleNactivityRead.vue'
                  ),
              },
              {
                path: 'articleNactivityAdd',
                name: '編輯室新增',
                component: () =>
                  import(
                    '@/views/store/editor/articleNactivity/articleNactivityAdd.vue'
                  ),
              },
              {
                path: 'articleNactivityEdit/:articleNactivityId',
                name: '編輯室編輯',
                component: () =>
                  import(
                    '@/views/store/editor/articleNactivity/articleNactivityEdit.vue'
                  ),
              },
              {
                path: 'articleNactivityPreview',
                name: '編輯室預覽',
                component: () =>
                  import(
                    '@/views/store/editor/articleNactivity/articleNactivityPreview.vue'
                  ),
                beforeEnter: () => {
                  if (!store.state.storeArticleNactivity) {
                    return {
                      path: history.back().then(() => {
                        window.location.reload()
                      }),
                    }
                  }
                },
              },
            ],
          },
          {
            path: 'gridImageGalleryManage',
            name: '小商店編輯室分頁主圖',
            component: () =>
              import('@/views/store/editor/gridImageGalleryManage.vue'),
          },
          {
            path: 'articleTagsManage',
            name: '小商店標籤管理',
            component: () =>
              import('@/views/store/editor/articleTagsManage.vue'),
          },
        ],
      },
      {
        path: '/store/thangkaBlock',
        name: '小商店唐卡專區',
        redirect: '/store/thangkaBlock/gridImageGalleryManage',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'gridImageGalleryManage',
            name: '小商店唐卡分頁主圖',
            component: () =>
              import('@/views/store/thangkaBlock/gridImageGalleryManage.vue'),
          },
          {
            path: 'selectedCategoryManage',
            name: '小商店精選類別管理',
            component: () =>
              import('@/views/store/thangkaBlock/selectedCategoryManage.vue'),
          },
          {
            path: 'selectedRecentlyManage',
            name: '小商店近期精選管理',
            component: () =>
              import('@/views/store/thangkaBlock/selectedRecentlyManage.vue'),
          },
        ],
      },
      {
        path: '/store/systemSetting',
        name: '小商店系統設定',
        redirect: '/store/systemSetting/logoManage',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'logoManage',
            name: '小商店Logo管理',
            component: () =>
              import('@/views/store/systemSetting/logoManage.vue'),
          },
        ],
      },
      {
        path: '/classic/homepage',
        name: '聞法室首頁',
        redirect: '/classic/homepage/carousel',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: '聞法室大圖管理',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/classic/homepage/carousel',
            children: [
              {
                path: 'carousel',
                name: '聞法室大圖管理列表',
                component: () =>
                  import('@/views/classic/homepage/carousel/carousel.vue'),
              },
              {
                path: 'carousel/carouselAdd',
                name: '聞法室新增大圖管理',
                component: () =>
                  import('@/views/classic/homepage/carousel/carouselAdd.vue'),
              },
              {
                path: 'carousel/carouselEdit/:id',
                name: '聞法室編輯大圖管理',
                component: () =>
                  import('@/views/classic/homepage/carousel/carouselEdit.vue'),
              },
            ],
          },
          {
            path: 'latestArticleManage',
            name: '聞法室最新文章管理',
            component: () =>
              import('@/views/classic/homepage/latestArticleManage.vue'),
          },
          {
            path: 'featuredCoursesManage',
            name: '聞法室精選課程管理',
            component: () =>
              import('@/views/classic/homepage/featuredCoursesManage.vue'),
          },
          {
            path: 'trendingHashTagManage',
            name: '聞法室關鍵字管理',
            component: () =>
              import('@/views/classic/homepage/trendingHashTagManage.vue'),
          },
        ],
      },
      {
        path: '/classic/articleNNews',
        name: '聞法室文章/最新消息',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/classic/articleNNews/articleNNews',
        children: [
          {
            path: '',
            name: '聞法室所有文章大圖管理',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/classic/articleNNews/carousel',
            children: [
              {
                path: 'carousel',
                name: '聞法室所有文章大圖管理列表',
                component: () =>
                  import('@/views/classic/articleNNews/carousel/carousel.vue'),
              },
              {
                path: 'carousel/carouselAdd',
                name: '聞法室所有文章新增大圖管理',
                component: () =>
                  import(
                    '@/views/classic/articleNNews/carousel/carouselAdd.vue'
                  ),
              },
              {
                path: 'carousel/carouselEdit/:id',
                name: '聞法室所有文章編輯大圖管理',
                component: () =>
                  import(
                    '@/views/classic/articleNNews/carousel/carouselEdit.vue'
                  ),
              },
            ],
          },
          {
            path: '',
            redirect: '/classic/articleNNews/articleNNews',
            name: '聞法室文章/最新消息專區',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            // redirect: '/articleNactivityBlock/articleNactivity',
            children: [
              {
                path: 'articleNNews',
                name: '聞法室文章/最新消息列表',
                component: () =>
                  import(
                    '@/views/classic/articleNNews/articleNNews/articleNNews.vue'
                  ),
              },
              {
                path: 'articleNNewsAdd',
                name: '聞法室文章/最新消息新增',
                component: () =>
                  import(
                    '@/views/classic/articleNNews/articleNNews/articleNNewsAdd.vue'
                  ),
              },
              {
                path: 'articleNNewsEdit/:id',
                name: '聞法室文章/最新消息編輯',
                component: () =>
                  import(
                    '@/views/classic/articleNNews/articleNNews/articleNNewsEdit.vue'
                  ),
              },
              {
                path: 'articleNNewsRead/:id',
                name: '聞法室文章/最新消息檢視',
                component: () =>
                  import(
                    '@/views/classic/articleNNews/articleNNews/articleNNewsRead.vue'
                  ),
              },
              {
                path: 'articleNNewsPreview',
                name: '聞法室文章/最新消息預覽',
                component: () =>
                  import(
                    '@/views/classic/articleNNews/articleNNews/articleNNewsPreview.vue'
                  ),
                beforeEnter: () => {
                  if (!store.state.classicArticleNNews) {
                    return {
                      path: history.back().then(() => {
                        window.location.reload()
                      }),
                    }
                  }
                },
              },
            ],
          },
          {
            path: 'tagManage',
            name: '聞法室標籤管理',
            component: () =>
              import('@/views/classic/articleNNews/tagManage.vue'),
          },
        ],
      },
      {
        path: '/classic/videosBlock',
        name: '聞法室影音專區',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/classic/videosBlock/videoManage',
        children: [
          {
            path: 'videoManage',
            name: '聞法室影音管理',
            component: () =>
              import('@/views/classic/videosBlock/videosManage.vue'),
          },
        ],
      },
      {
        path: '/classic/systemSetting',
        name: '聞法室系統設定',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/systemSetting/announcementManage',
        children: [
          {
            path: 'announcementManage',
            name: '聞法室最新廣告',
            component: () =>
              import('@/views/classic/systemSetting/announcementManage.vue'),
          },
          {
            path: 'logoManage',
            name: '聞法室Logo管理',
            component: () =>
              import('@/views/classic/systemSetting/logoManage.vue'),
          },
        ],
      },
      {
        path: '/authority',
        name: '共有的權限管理',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/authority/accountManage',
        children: [
          {
            path: 'accountManage',
            name: '共有的帳號管理',
            component: () => import('@/views/authority/accountManage.vue'),
          },
          {
            path: 'userImport',
            name: '共有的會員匯入',
            component: () => import('@/views/authority/userImport.vue'),
          },
        ],
      },

      // {
      //   path: '/dashboard',
      //   name: 'Dashboard',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () =>
      //     import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      // },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: () => import('@/views/pages/ForgotPassword'),
      },
      // {
      //   path: 'register',
      //   name: 'Register',
      //   component: () => import('@/views/pages/Register'),
      // },
    ],
  },
]

const router = createRouter({
  // HTML5 Hash 模式 -> createWebHashHistory()
  // history: createWebHashHistory(process.env.BASE_URL),
  // HTML5 (History API) 模式 -> createWebHistory()
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(async (to) => {
  let logging = false
  let authority = ''
  let storeAuthority = ''
  let classicAuthority = ''
  let userAccountAuthority = ''
  const user = await Parse.User.current()
  store.commit('updateUser', user)
  // await Parse.User.logOut()

  if (user) {
    logging = true
    authority = user.get('authority')
    storeAuthority = user.get('store_authority')
    classicAuthority = user.get('classic_authority')
    userAccountAuthority = user.get('userAccount_authority')
  } else {
    logging = false
  }
  if (to.name !== 'Login' && to.name !== 'ForgotPassword') {
    if (!logging) {
      return '/pages/login'
    } else if (router.hasRoute(to.name)) {
      const routesAuthority = {
        '/homepage': 'homepage',
        '/articleNactivityBlock': 'articleNactivity',
        '/drawLots': 'drawLots',
        '/videosBlock': 'videos',
        '/systemSetting': 'settings',
        '/store/homepage': 'homepage',
        '/store/onlineBookStore': 'onlineBookStore',
        '/store/order': 'order',
        '/store/editor': 'editor',
        '/store/thangkaBlock': 'thangkaBlock',
        '/store/systemSetting': 'systemSetting',
        '/classic/homepage': 'homepage',
        '/classic/articleNNews': 'articleNNews',
        '/classic/videosBlock': 'videos',
        '/classic/systemSetting': 'settings',
        '/authority': 'authority',
      }
      const path = Object.keys(routesAuthority).find((key) =>
        to.path.startsWith(key),
      )
      const requiredAuthority = routesAuthority[path]
      // console.log('path', path, requiredAuthority)
      // console.log(
      //   authority,
      //   storeAuthority,
      //   classicAuthority,
      //   userAccountAuthority,
      // )
      if (!path) {
        return true
      }
      if (path.startsWith('/store')) {
        if (!storeAuthority.includes(requiredAuthority)) {
          alert('缺少權限')
          return '/welcome'
        }
      } else if (path.startsWith('/authority')) {
        if (!userAccountAuthority || !userAccountAuthority[0]) {
          alert('缺少權限')
          return '/welcome'
        }
      } else if (path.startsWith('/classic')) {
        if (!classicAuthority.includes(requiredAuthority)) {
          alert('缺少權限')
          return '/welcome'
        }
      } else {
        if (!authority.includes(requiredAuthority)) {
          alert('缺少權限')
          return '/welcome'
        }
      }
    } else {
      return history.back()
    }
  } else if (to.name === 'Login' || to.name === 'ForgotPassword') {
    if (logging) {
      alert('缺少權限')
      return '/welcome'
    }
  }
})

export default router
